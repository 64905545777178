import * as PIXI from "pixi.js";
import { PixiUtils } from "./pixi.utils";
import { roundToOneDecimal } from "../../utils/formators";
import defaultLogo from "./Logo/not-found.png";

// Load images in /Logo folder
const images = require.context("./Logo", false, /\.(png|jpe?g|webp)$/);
const logoMap = {};
images.keys().forEach((key) => {
  const symbol = key
    .replace("./", "")
    .replace(/\.(png|jpe?g|webp)$/, "")
    .toUpperCase();
  logoMap[symbol] = images(key);
});

// Helper: lighten a hex color
function lightenColor(hex, amount = 0.2) {
  let color = hex.replace("#", "");
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  r = Math.min(255, Math.floor(r + (255 - r) * amount));
  g = Math.min(255, Math.floor(g + (255 - g) * amount));
  b = Math.min(255, Math.floor(b + (255 - b) * amount));

  const newHex =
    "#" +
    (r < 16 ? "0" : "") +
    r.toString(16) +
    (g < 16 ? "0" : "") +
    g.toString(16) +
    (b < 16 ? "0" : "") +
    b.toString(16);
  return newHex.toUpperCase();
}

export const appConfig = {
  width: typeof window !== "undefined" ? window.innerWidth - 20 : 100,
  height: typeof window !== "undefined" ? window.innerHeight - 180 : 100,
  speed: 0.005,
  elasticity: 0.005,
  wallDamping: 0.5,
  maxCircleSize: 90,
  minCircleSize: 35
  // typeof window !== "undefined"
  //   ? window.innerWidth
  //     ? window.innerWidth > 920
  //       ? 35
  //       : 28
  //     : 28
  //   : 28,
};

const {
  wallDamping,
  width,
  height,
  speed,
  elasticity,
  maxCircleSize,
  minCircleSize,
} = appConfig;

// Smoother radius transitions
const easingFactor = 0.08;

export class BubblesUtils {
  static getScalingFactor = (data, bubbleSort = "dailyPerformance") => {
    if (!data) return 1;

    // Summation of areas for the chosen field
    const max = data.map((item) => Math.abs(+item[bubbleSort]));
    let totalSquare = 0;
    for (let i = 0; i < max.length; i++) {
      const area = Math.PI * max[i] * max[i];
      totalSquare += area;
    }
    // Fit area inside canvas, scale for desktop vs. mobile
    return (
      // Math.sqrt((width * height) / totalSquare) * (width > 920 ? 0.8 : 0.5)
      Math.sqrt((width * height) / totalSquare) * (0.7)
    );
  };

  static update = (
    circles,
    imageSprites,
    textSprites,
    text2Sprites,
    circleGraphics = []
  ) => {
    return () => {
      for (let i = 0; i < circles.length; i++) {
        const circle = circles[i];
        const circleGraphic = circleGraphics[i];
        const imageSprite = imageSprites[i];
        const text = textSprites[i];
        const text2 = text2Sprites[i];



        // Function to update circle visuals
        const updateCircleChilds = () => {
          if (text2._text.includes('%')) {
            const numericValue = parseFloat(text2._text.replace('%', ''));
            if ((numericValue >= 0.1 && numericValue <= 1.0) || (numericValue >= -1.0 && numericValue <= -0.1)) {
              circleGraphic.texture = PixiUtils.createGradientTexture(
                circle.radius * 2,
                circle.color
              );
            } else {
              circleGraphic.texture = PixiUtils.createGradientTexture(
                circle.radius * 4,
                circle.color
              );
            }
          }
          else if (text2._text.includes('M') || text2._text.includes('B') || text2._text.includes('K')) {
            circleGraphic.texture = PixiUtils.createGradientTexture(
              circle.radius * 4,
              circle.color
            );
          } else {
            circleGraphic.texture = PixiUtils.createGradientTexture(
              circle.radius * 2,
              circle.color
            );
          }


          // Adjust text font sizes
          const baseFontSize = circle.radius * 0.28;
          const symbolLength = circle.symbol ? circle.symbol.length : 1;
          let finalFontSize = baseFontSize;
          if (symbolLength > 4) {
            finalFontSize -= (symbolLength - 4) * 1.2;
          }
          if (finalFontSize < 10) finalFontSize = 6;


          if (text2._text.includes('%')) {
            const numericValue = parseFloat(text2._text.replace('%', ''));
            if ((numericValue >= 0.1 && numericValue <= 1.0) || (numericValue >= -1.0 && numericValue <= -0.1)) {
              const textStyle = new PIXI.TextStyle({
                fontSize: "2px",
                fill: "#000000",
              });
              text.style = textStyle;
              text.visible = false
              text.position.y = 0.19 * circle.radius;
              // For text2
              let secondFontSize = circle.radius * 0.2;
              if (secondFontSize < 10) secondFontSize = 6;
              const text2Style = new PIXI.TextStyle({
                fontSize: "2px",
                fill: "#000000",
              });
              text2.style = text2Style;
              text2.visible = false
              text2.position.y = circle.radius / 1.8;
            } else {
              const textStyle = new PIXI.TextStyle({
                fontSize: finalFontSize + "px",
                fill: "#ffffff",
              });
              text.style = textStyle;
              text.position.y = 0.19 * circle.radius;
              // For text2
              let secondFontSize = circle.radius * 0.2;
              if (secondFontSize < 10) secondFontSize = 6;
              const text2Style = new PIXI.TextStyle({
                fontSize: secondFontSize + "px",
                fill: "#ffffff",
              });
              text2.style = text2Style;
              text2.position.y = circle.radius / 1.8;
            }
          }
          else if (text2._text.includes('M') || text2._text.includes('B') || text2._text.includes('K')) {
            const textStyle = new PIXI.TextStyle({
              fontSize: finalFontSize + "px",
              fill: "#ffffff",
            });
            text.style = textStyle;
            text.position.y = 0.19 * circle.radius;
            // For text2
            let secondFontSize = circle.radius * 0.2;
            if (secondFontSize < 10) secondFontSize = 6;
            const text2Style = new PIXI.TextStyle({
              fontSize: secondFontSize + "px",
              fill: "#ffffff",
            });
            text2.style = text2Style;
            text2.position.y = circle.radius / 1.8;
          }


          // Image
          const isMobile =
            typeof window !== "undefined" && window.innerWidth < 920;

          if (imageSprite) {
            if (text2._text.includes('%')) {
              const numericValue = parseFloat(text2._text.replace('%', ''));
              if ((numericValue >= 0.1 && numericValue <= 1.0) || (numericValue >= -1.0 && numericValue <= -0.1)) {
                const isFullSize = circle.radius * 0.5 < 14;
                imageSprite.width = circle.radius * (isFullSize ? 0.8 : 0.5);
                imageSprite.height = circle.radius * (isFullSize ? 0.3 : 0.3);
                imageSprite.position = {
                  x: 0,
                  y: 0,
                };
              } else {
                const isFullSize = circle.radius * 0.5 < 18;
                imageSprite.width = circle.radius * (isFullSize ? 0.8 : 0.6);
                imageSprite.height = circle.radius * (isFullSize ? 0.3 : 0.3);
                imageSprite.position = {
                  x: 0,
                  y: isMobile ? -10 : -16,
                };
              }
            }
            else if (text2._text.includes('M') || text2._text.includes('B') || text2._text.includes('K')) {
              const isFullSize = circle.radius * 0.5 < 18;
              imageSprite.width = circle.radius * (isFullSize ? 0.8 : 0.6);
              imageSprite.height = circle.radius * (isFullSize ? 0.3 : 0.3);
              imageSprite.position = {
                x: 0,
                y: isMobile ? -10 : -16,
              };
            } else {
              const isFullSize = circle.radius * 0.5 < 14;
              imageSprite.width = circle.radius * (isFullSize ? 0.8 : 0.5);
              imageSprite.height = circle.radius * (isFullSize ? 0.3 : 0.3);
              imageSprite.position = {
                x: 0,
                y: 0,
              };
            }
          }
        };

        // 1) Move the circle
        circle.x += circle.vx;
        circle.y += circle.vy;

        // 2) Collision with walls
        if (circle.x - circle.radius < 0) {
          circle.x = circle.radius;
          circle.vx *= -1;
          circle.vx *= 1 - wallDamping;
        } else if (circle.x + circle.radius > width) {
          circle.x = width - circle.radius;
          circle.vx *= -1;
          circle.vx *= 1 - wallDamping;
        }
        if (circle.y - circle.radius < 0) {
          circle.y = circle.radius;
          circle.vy *= -1;
          circle.vy *= 1 - wallDamping;
        } else if (circle.y + circle.radius > height) {
          circle.y = height - circle.radius;
          circle.vy *= -1;
          circle.vy *= 1 - wallDamping;
        }

        // 3) Collision with other circles
        for (let j = i + 1; j < circles.length; j++) {
          const otherCircle = circles[j];
          const dx = otherCircle.x - circle.x;
          const dy = otherCircle.y - circle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const minDist = circle.radius + otherCircle.radius;

          if (distance < minDist) {
            // Colliding
            const angle = Math.atan2(dy, dx);
            const overlap = minDist - distance;
            const force = overlap * elasticity;

            const halfOverlap = overlap / 2;
            const cos = Math.cos(angle);
            const sin = Math.sin(angle);

            circle.x -= halfOverlap * cos;
            circle.y -= halfOverlap * sin;
            otherCircle.x += halfOverlap * cos;
            otherCircle.y += halfOverlap * sin;

            circle.vx -= force * cos * wallDamping + circle.vx * 0.01;
            circle.vy -= force * sin * wallDamping + circle.vy * 0.01;
            otherCircle.vx += force * cos * wallDamping;
            otherCircle.vy += force * sin * wallDamping;
          }
        }

        // 4) Update the container’s position
        const container = circleGraphic.parent;
        container.position.set(circle.x, circle.y);

        // 4.5) Border on hover
        let borderGraphics = container.getChildByName("borderGraphics");
        if (!borderGraphics) {
          borderGraphics = new PIXI.Graphics();
          borderGraphics.name = "borderGraphics";
          container.addChild(borderGraphics);
        }
        borderGraphics.clear();

        if (circle.hovered) {
          borderGraphics.lineStyle(1, 0xffffff, 1);
          if (text2._text.includes('%')) {
            const numericValue = parseFloat(text2._text.replace('%', ''));
            if ((numericValue >= 0.1 && numericValue <= 1.0) || (numericValue >= -1.0 && numericValue <= -0.1)) {
              borderGraphics.drawCircle(0, 0, circle.radius / 2);
            } else {
              borderGraphics.drawCircle(0, 0, circle.radius);
            }
          }
          else if (text2._text.includes('M') || text2._text.includes('B') || text2._text.includes('K')) {
            borderGraphics.drawCircle(0, 0, circle.radius);
          } else {
            borderGraphics.drawCircle(0, 0, circle.radius / 2);
          }
          // borderGraphics.drawCircle(0, 0, circle.radius);
        }

        // 5) Animate radius
        if (circle.radius !== circle.targetRadius) {
          // Remove cacheAsBitmap to keep pointer events
          // container.cacheAsBitmap = false;

          const sizeDiff = circle.targetRadius - circle.radius;
          circle.radius += sizeDiff * easingFactor;

          if (Math.abs(sizeDiff) < 0.1) {
            circle.radius = circle.targetRadius;
            // container.cacheAsBitmap = true;  // Potentially breaks hover
          } else {
            updateCircleChilds();
          }
        }

        // ---- CRITICAL: update hitArea after radius changes
        container.hitArea = new PIXI.Circle(0, 0, circle.radius);
      }
    };
  };

  static handleEmptySpaceClick = (event, circles) => {
    const waveForce = 100;
    circles.forEach((circle) => {
      const dx = circle.x - event.clientX;
      const dy = circle.y - event.clientY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const angle = Math.atan2(dy, dx);

      // Push away from click
      circle.vx += (waveForce * Math.cos(angle)) / distance;
      circle.vy += (waveForce * Math.sin(angle)) / distance;
    });
  };

  static handleMouseMove = (event, circles) => {
    const index = circles.findIndex((circle) => circle.dragging);
    if (index !== -1) {
      const circle = circles[index];
      const dx = event.clientX - circle.x;
      const dy = event.clientY - circle.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const speedFactor = 3;
      circle.vx = (dx / distance) * speedFactor;
      circle.vy = (dy / distance) * speedFactor;
    }
  };

  static generateCircles = (
    coins,
    scalingFactor,
    bubbleSort = "dailyPerformance",
    schemeColor,
    bubbleContent,
    activeValue
  ) => {
    const isMobile =
      typeof window !== "undefined" && window.innerWidth < 920;

    const shapes = coins.map((item) => {
      const scaling = scalingFactor !== Infinity ? scalingFactor : 0;
      const itemData = item[bubbleSort];
      const radius = Math.abs(itemData * scaling) ?? 0;
      const symbol = item.symbol?.toUpperCase() || "";
      const baseImg = logoMap[symbol] || defaultLogo;


      const scaleModifier = isMobile ? 0.5 : 0.8;
      // const scaleModifier = 0.8;

      let baseColor =
        item[bubbleSort] > 0
          ? schemeColor.success.name
          : item[bubbleSort] < 0
            ? schemeColor.danger.name
            : schemeColor.neutral.name;

      // Lighter for hover
      const hoverColor = lightenColor(baseColor, 0.2);

      const data = {
        id: item.id,
        symbol: item.symbol,
        originalColor: baseColor,
        hoverColor: hoverColor,
        color: baseColor, // start with original color
        image: baseImg,
        stockName: item.symbol,

        x: Math.random() * (width - radius * 2),
        y: Math.random() * (height - radius * 2),
        vx: Math.random() * speed * 2 - speed,
        vy: Math.random() * speed * 2 - speed,

        targetRadius:
          Math.max(minCircleSize, Math.min(radius, maxCircleSize)) *
          scaleModifier,
        radius: 0, // start from 0 or 1
        dragging: false,
        hovered: false,

        dailyPerformance: item["dailyPerformance"],
        volume: item["volume"],
        market_cap: item["marketCap"],
        weeklyPerformance: roundToOneDecimal(item["weeklyPerformance"]),
        monthlyPerformance: roundToOneDecimal(item["monthlyPerformance"]) ?? 1,
        yearlyPerformance: roundToOneDecimal(item["yearlyPerformance"]),
        yearToDatePerformance: roundToOneDecimal(
          item["yearToDatePerformance"]
        ),
        weekData: item["weekData"],
        monthData: item["monthData"],
        yearData: item["yearData"],
        rank: item["rank"],
        Last: item["Last"],
        maxPerformance: item["maxPerformance"],
        volumeP: (item.volume || 0) * (item.Last || 0),
      };

      return {
        ...data,
        // custom text or label
        text2: PixiUtils.createText2(data, bubbleContent, activeValue),
      };
    });

    return shapes;
  };
}


