import React, { useState, useEffect, useMemo, useRef } from "react";
import Modal from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChartData from "./Modal/ChartData";
import { formatCurrency, roundToOneDecimal } from "../utils/formators";
import { useWebSocket } from "../WebSocketContext";
import { setPagination } from "../reducers/slices/StockSlice";
import "./Table.css";

import { setAllData, setLoaderIs } from "../reducers/slices/SettingsSlice";
import { isEqual } from "lodash"; // Import isEqual for deep comparison

const Table = () => {
  useEffect(() => {
    // Select DOM elements
    const header = document.querySelector('header');
    const tableHeader = document.querySelector('thead.abc');
    const tableSection = document.querySelector('.table-section');
    const tableBody = document.querySelector('tbody.abc2');

    // Ensure all elements are present
    if (!header || !tableHeader || !tableSection) {
      console.warn("One or more elements not found for the scroll script.");
      return;
    }

    const headerHeight = 98;

    // Function to calculate translateY in vh
    const calculateTranslateY = () => {
      const viewportHeight = window.innerHeight;
      const threshold = 1.04 * viewportHeight; // 87vh in pixels
      const scrollPosition = window.pageYOffset;

      if (scrollPosition >= threshold - headerHeight) {
        if (tableBody) {
          // Add the 'stopp' class to the existing classes
          tableBody.classList.add('stopp2');
        } else {
          const elementsWithStopp = document.querySelectorAll('.stopp2');
          elementsWithStopp.forEach(element => {
            element.classList.remove('stopp2');
            console.log("Removed 'stopp' class from an element.");
          });
        }
        if (tableHeader) {
          // Add the 'stopp' class to the existing classes
          tableHeader.classList.add('stopp');
        } else {
          const elementsWithStopp = document.querySelectorAll('.stopp');
          elementsWithStopp.forEach(element => {
            element.classList.remove('stopp');
            console.log("Removed 'stopp' class from an element.");
          });
        }
        // Calculate how much the user has scrolled past the threshold
        const excessScroll = scrollPosition - (threshold - headerHeight);
        // Convert excess scroll from px to vh
        const translateYVh = (excessScroll / viewportHeight) * 1;

        // Optionally, cap the translateY to prevent excessive movement
        const finalTranslateYVh = Math.min(translateYVh);

        // tableHeader.style.transform = `translateY(${finalTranslateYVh }vh)`;
      } else {
        tableHeader.style.transform = `translateY(0)`;
        const elementsWithStopp = document.querySelectorAll('.stopp');
        elementsWithStopp.forEach(element => {
          element.classList.remove('stopp');
          console.log("Removed 'stopp' class from an element.");
        });

        const elementsWithStopp2 = document.querySelectorAll('.stopp2');
        elementsWithStopp2.forEach(element => {
          element.classList.remove('stopp2');
          console.log("Removed 'stopp' class from an element.");
        });
      }
    };

    // Initial check in case the page is loaded already scrolled
    calculateTranslateY();

    // Attach the scroll event listener
    window.addEventListener('scroll', calculateTranslateY);

    // Optional: Handle window resize to recalculate values
    window.addEventListener('resize', calculateTranslateY);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', calculateTranslateY);
      window.removeEventListener('resize', calculateTranslateY);
    };
  }, []);
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Set initial size
      handleResize();

      // Cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  // 2. Use the hook in the component
  const { width } = useWindowSize();
  const isSmallerThan1100 = width && width < 1100;
  // Local state for modal management
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  // WebSocket connection
  const socket = useWebSocket();

  // Local state for stocks and loading status
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux selectors
  const selectedAsset = useSelector((state) => state.Settings.selectedAsset);
  const selectedSector = useSelector((state) => state.Settings.selectedSector);
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const selectedPagination = useSelector((state) => state.Stocks.selectedPage);
  const selectedMenue = useSelector((state) => state.Settings.selectedMenue);

  const prevFilteredStocksRef = useRef([]);

  // ─────────────────────────────────────────────────────────────
  // 1) SORTING STATE (Local Only)
  // ─────────────────────────────────────────────────────────────
  const [sortConfig, setSortConfig] = useState({
    key: null,        // which column are we sorting by?
    direction: "asc", // 'asc' or 'desc'
  });

  // Handle column sort toggling
  const handleSort = (columnKey) => {
    setSortConfig((prev) => {
      if (prev.key === columnKey) {
        // Toggle direction if the same column
        return {
          ...prev,
          direction: prev.direction === "asc" ? "desc" : "asc",
        };
      }
      // Otherwise, sort by a new column in ascending order
      return { key: columnKey, direction: "asc" };
    });
  };

  // Optional helper to show an arrow next to the sorted column
  const renderSortIndicator = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    return sortConfig.direction === "asc" ? " ↑" : " ↓";
  };




  // Load images in /Logo folder
  const handleImage = (item) => {
    const symbol = item?.toUpperCase() || "";
    const imagePath = `/assets/Logo/${symbol}.png`;
    return (
      <img
        src={imagePath}
        alt={symbol}
        onError={(e) => {
          e.target.src = "/assets/Logo/not-found.png";
        }}
        style={{ width: 40, height: 20 }}
      />
    );
  };


  // ─────────────────────────────────────────────────────────────
  // 2) LOAD DATA FROM WEBSOCKET
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!socket) return;
    dispatch(setLoaderIs(true));

    const handleMessage = (event) => {
      try {

        const data = JSON.parse(event.data);
        const filteredData = data
        // .filter((item) => {
        //   debugger;
        //   let itemDate = item.Timestamp.split("T")[0];

        //   const todayDate = new Date();

        //   const options = { timeZone: "Africa/Lagos" };
        //   const nigerianDate = new Date(
        //     todayDate.toLocaleString("en-US", options)
        //   );

        //   const isBefore9AM = nigerianDate.getHours() < 9;

        //   // console.log("Nigerian Time:", nigerianDate);
        //   // console.log("Is before 9 AM:", isBefore9AM);
        //   const tradingDate = getAdjustedTradingDate(nigerianDate, isBefore9AM);
        //   const formattedTradingDate = formatToDateOnly(
        //     tradingDate,
        //     "Africa/Lagos"
        //   );

        //   // console.log("tradingDate:", tradingDate);
        //   console.log("Formatted Trading Date:", formattedTradingDate);

        //   return itemDate == formattedTradingDate;
        // });

        //   const filteredData = data.filter(item => {
        //     const itemDate = new Date(item.Timestamp);

        //     // Normalize the timestamp to the date only (set hours, minutes, seconds, milliseconds to 0)
        //     const normalizedItemDate = new Date(itemDate.setHours(0, 0, 0, 0));

        //     // Get today's start and tomorrow's start (normalize today's date as well)
        //     const todayStart = new Date();
        //     todayStart.setHours(0, 0, 0, 0); // Set to start of the day

        //     const tomorrowStart = new Date(todayStart);
        //     tomorrowStart.setDate(todayStart.getDate() + 1); // Set to start of the next day

        //     // Check if the item's date (ignoring time) is between todayStart and tomorrowStart
        //     return normalizedItemDate >= todayStart && normalizedItemDate < tomorrowStart;
        // });

        // // Get the start of today (midnight)
        // const todayStart = new Date();
        // todayStart.setHours(0, 0, 0, 0);

        // // Get the start of tomorrow (to create an exclusive upper bound)
        // const tomorrowStart = new Date(todayStart);
        // tomorrowStart.setDate(todayStart.getDate() + 1);
        // console.log(data,"datadatadatadatadatadatadatadatadatadatadatadatadata")

        // const filteredData = data.filter(item => {
        //   console.log(item,"item")
        //     const itemDate = new Date(item.Timestamp * 1000);
        //     console.log(todayStart,"todayStart")
        //     return itemDate >= (todayStart && itemDate) < tomorrowStart;
        // });


        // const modifiedData = data.map((item, index) => {
        //   // Add 'CONSTRUCTION/REAL ESTATE' sector to the first three items
        //   if (index < 10) {
        //     return { ...item, Sector: 'CONSTRUCTION/REAL ESTATE' };
        //   }
        //   // Add 'FINANCIAL SERVICES' sector to all other items
        //   return { ...item, Sector: 'FINANCIAL SERVICES' };
        // });

        // const afterremove = data.filter(obj => {
        //   // Check if any key matches the condition

        //   return Object.keys(obj).some(key =>
        //     key.includes('Symbol') && /\[.*?\]/.test(obj[key])
        //   );

        // });
        // console.log(afterremove,"afterremove")

        // const cleanedArray = data.filter(obj => {
        // Check if any key matches the condition

        //     return !Object.keys(obj).some(key =>
        //       key.includes('Symbol') && /\[.*?\]/.test(obj[key])
        //     );

        //   });
        setStocks(filteredData);
        setLoading(false);
        dispatch(setLoaderIs(false));
      } catch (error) {
        dispatch(setLoaderIs(false));
        console.error("Error parsing WebSocket data:", error);
      }
    };

    socket.addEventListener("message", handleMessage);
    return () => {
      socket.removeEventListener("message", handleMessage);
      socket.close();
    };
  }, [socket, dispatch]);

  // (Utility functions originally in your code)
  function getAdjustedTradingDate(currentDate, isBeforeMarketOpen) {
    const adjustedDate = new Date(currentDate);
    if (isBeforeMarketOpen) {
      const dayOfWeek = adjustedDate.getDay();
      if (dayOfWeek === 0) {
        // Sunday
        adjustedDate.setDate(adjustedDate.getDate() - 2);
      } else if (dayOfWeek === 1) {
        // Monday
        adjustedDate.setDate(adjustedDate.getDate() - 3);
      } else {
        // Any other day
        adjustedDate.setDate(adjustedDate.getDate() - 1);
      }
    }
    return adjustedDate;
  }

  function formatToDateOnly(date, timeZone) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // ─────────────────────────────────────────────────────────────
  // 3) FILTER DATA (STORED IN REDUX) - NO SORTING YET
  // ─────────────────────────────────────────────────────────────
  // We only dispatch "filtered" data to Redux, so it won't re-dispatch on sorts.
  const filteredStocks = useMemo(() => {
    return stocks.filter((item) => {
      const asset = item.Asset ? item.Asset.toUpperCase().trim() : "";
      const sector = item.Sector ? item.Sector.toLowerCase().trim() : "";
      const selectedSectorLower = selectedSector
        ? selectedSector.toLowerCase().trim()
        : "";

      let assetMatch = false;
      let sectorMatch = false;

      // Determine asset match
      switch (selectedAsset.name.toUpperCase()) {
        case "EQUITY":
          assetMatch = asset === "EQUITY";
          break;
        case "DEBT":
          assetMatch = asset === "DEBT";
          break;
        case "ETF":
          assetMatch = asset === "ETF";
          break;
        case "ALL":
          assetMatch = true;
          break;
        default:
          assetMatch = false;
      }

      // Determine sector match (only relevant if Equity)
      if (selectedAsset.name.toUpperCase() === "EQUITY") {
        if (selectedSectorLower === "all sectors") {
          sectorMatch = true;
        } else {
          sectorMatch = sector === selectedSectorLower;
        }
      } else {
        // For non-Equity assets, we ignore sector
        sectorMatch = true;
      }

      return assetMatch && sectorMatch;
    });
  }, [stocks, selectedAsset, selectedSector]);

  // Dispatch "filtered" data to Redux only when the data changes, not when sorted
  useEffect(() => {
    // Update pagination count in Redux
    // dispatch(setPagination(filteredStocks.length));

    // Compare previous and current filteredStocks
    if (!isEqual(prevFilteredStocksRef.current, filteredStocks)) {
      // Dispatch the filtered data (no sorting)
      dispatch(setAllData(filteredStocks));
      prevFilteredStocksRef.current = filteredStocks;
    }
  }, [filteredStocks, dispatch]);

  // ─────────────────────────────────────────────────────────────
  // 4) SORT DATA LOCALLY (NO DISPATCH)
  // ─────────────────────────────────────────────────────────────
  const sortedStocks = useMemo(() => {
    // If no column is specifically sorted, keep your default sort by PerChange desc
    if (!sortConfig.key) {
      return [...filteredStocks].sort(
        (a, b) => (b.PerChange || 0) - (a.PerChange || 0)
      );
    }

    const { key, direction } = sortConfig;
    const sorted = [...filteredStocks];

    sorted.sort((a, b) => {
      // Numeric columns
      const numericCols = [
        "Close",
        "Volume",
        "Value",
        "PerChange",
        "weeklyPerformance",
        "monthlyPerformance",
        "yearlyToDatePerformance",
        "yearlyPerformance",
      ];

      if (key === "Symbol") {
        // Sort as string
        const strA = (a.Symbol || "").toLowerCase();
        const strB = (b.Symbol || "").toLowerCase();
        if (strA < strB) return direction === "asc" ? -1 : 1;
        if (strA > strB) return direction === "asc" ? 1 : -1;
        return 0;
      } else if (numericCols.includes(key)) {
        // Sort as number
        const numA = parseFloat(a[key] ?? 0);
        const numB = parseFloat(b[key] ?? 0);
        return direction === "asc" ? numA - numB : numB - numA;
      } else if (key === "TradeDate") {
        // Sort as date
        const dateA = new Date(a.TradeDate);
        const dateB = new Date(b.TradeDate);
        return direction === "asc" ? dateA - dateB : dateB - dateA;
      } else if (key === "rowIndex") {
        // Sorting by # (row index in filtered array)
        const indexA = filteredStocks.indexOf(a);
        const indexB = filteredStocks.indexOf(b);
        return direction === "asc" ? indexA - indexB : indexB - indexA;
      }
      return 0; // fallback
    });
    return sorted;
  }, [filteredStocks, sortConfig]);

  // ─────────────────────────────────────────────────────────────
  // 5) PAGINATION ON TOP OF SORTED DATA
  // ─────────────────────────────────────────────────────────────

  const startIndexNum = Math.max(0, parseInt(0) - 1); // zero-based index
  // const endIndexNum = Math.min(parseInt(endStr, 10), sortedStocks.length);

  // const paginatedStocks = useMemo(() => {
  //   return sortedStocks;
  // }, [sortedStocks, startIndexNum, endIndexNum]);

  // ─────────────────────────────────────────────────────────────
  // 6) MODAL HANDLERS
  // ─────────────────────────────────────────────────────────────
  const openModal = (data) => {
    setIsModalOpen(true);
    setSelectedData(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };


  // ─────────────────────────────────────────────────────────────
  // 7) RENDER
  // ─────────────────────────────────────────────────────────────
  return (

    <section
      className={`bg-[#222222] h-auto flex justify-center flex-col items-center ${selectedMenue === 2 ? "hidden-above-1100px" : "hidden-below-1100px"
        }`}
      id="table"
    >
      <PerfectScrollbar
        options={{ suppressScrollX: false }}
        className={
          isSmallerThan1100
            ? "w-[vw] relative max-h-[84vh] min-h-[84vh] overflow-y-auto tablesc"
            : "w-[vw] sticky top-0  relative max-h-full overflow-y-auto "
        }
      >
        <div className="table-wrapper table-section" >
          <table className="w-full text-sm text-left text-white">
            <thead className=" text-white uppercase bg-[#3D3D3D] abc">
              <tr className="headerClass">
                {/* # - Sorting by rowIndex */}
                <th
                  scope="col"
                  className="px-2 py-3 cursor-pointer " style={{
                    width: "6%",
                    minWidth: "6%",
                    maxWidth: "6%",
                  }}
                // If you want to sort by row index, uncomment:
                // onClick={() => handleSort("rowIndex")}
                >
                  #
                  {/* For example, if you want an indicator for rowIndex:
                  {renderSortIndicator("rowIndex")} */}
                </th>

                {/* Name (Symbol) */}
                <th
                  scope="col"
                  className={`name px-2 py-3 cursor-pointer ${sortConfig.key === "Symbol" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("Symbol")}
                >
                  Name
                  {renderSortIndicator("Symbol")}
                </th>

                {/* Price (Close) */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer  ${sortConfig.key === "Close" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("Close")}
                >
                  Price
                  {renderSortIndicator("Close")}
                </th>

                {/* Volume */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer  ${sortConfig.key === "Volume" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("Volume")}
                >
                  Volume (QTY)
                  {renderSortIndicator("Volume")}
                </th>

                {/* Value */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer  ${sortConfig.key === "Value" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("Value")}
                >
                  Value
                  {renderSortIndicator("Value")}
                </th>

                {/* Day (PerChange) */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer  ${sortConfig.key === "PerChange" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("PerChange")}
                >
                  Day
                  {renderSortIndicator("PerChange")}
                </th>

                {/* Week (weeklyPerformance) */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer ${sortConfig.key === "weeklyPerformance" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("weeklyPerformance")}
                >
                  Week
                  {renderSortIndicator("weeklyPerformance")}
                </th>

                {/* Month (monthlyPerformance) */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer ${sortConfig.key === "monthlyPerformance" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("monthlyPerformance")}
                >
                  Month
                  {renderSortIndicator("monthlyPerformance")}
                </th>

                {/* YTD (yearlyToDatePerformance) */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer ${sortConfig.key === "yearlyToDatePerformance" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("yearlyToDatePerformance")}
                >
                  YTD
                  {renderSortIndicator("yearlyToDatePerformance")}
                </th>

                {/* YoY (yearlyPerformance) */}
                <th
                  scope="col"
                  className={`px-2 py-3 cursor-pointer ${sortConfig.key === "yearlyPerformance" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  onClick={() => handleSort("yearlyPerformance")}
                >
                  YoY
                  {renderSortIndicator("yearlyPerformance")}
                </th>

                {/* Trade Date */}
                <th
                  scope="col"
                  className={`py-3 cursor-pointer ${sortConfig.key === "TradeDate" ? "bg-sky-700" : 'hover:bg-white hover:bg-opacity-50'}`}
                  // style={{ minWidth: "8.1vw"}}
                  onClick={() => handleSort("TradeDate")}
                >
                  Trade Date
                  {renderSortIndicator("TradeDate")}
                </th>
              </tr>
            </thead>
            <tbody className="text-lg abc2">
              {loading ? (
                <tr className=" transition-colors duration-200">
                  <td colSpan={14} className="text-center px-6 py-4">
                    Loading...
                  </td>
                </tr>
              ) : sortedStocks.length > 0 ? (
                sortedStocks.map((item, index) => (
                  <tr
                    key={item.id || index}
                    className="bg-[#222222] cursor-pointer  hover:bg-[#ffffff14]  transition-colors duration-200"
                    onClick={() => openModal(item)}
                  >
                    {/* # */}
                    <td scope="row" className="px-2 py-3 whitespace-nowrap" style={{
                      width: "6%",
                      minWidth: "6%",
                      maxWidth: "6%",
                    }}>
                      {startIndexNum + index + 1}
                    </td>

                    {/* Name (Symbol) */}
                    <td>
                      <div className="mt-1 bg-[#3D3D3D] w-max px-2 py-3 rounded-xl flex items-center gap-2">
                        {handleImage(item.Symbol)}
                        {item.Symbol}
                      </div>
                    </td>

                    {/* Price */}
                    <td className="px-2 py-3">
                      ₦ {formatCurrency(item.Close).replace("NGN", "")}
                    </td>

                    {/* Volume */}
                    <td className="px-2 py-3">
                      {formatCurrency(item.Volume).replace("NGN", "")}
                    </td>

                    {/* Value */}
                    <td className="px-2 py-3">
                      ₦ {formatCurrency(item.Value).replace("NGN", "")}
                    </td>

                    {/* Day, Week, Month, YTD, YoY cells */}
                    {[
                      "PerChange",
                      "weeklyPerformance",
                      "monthlyPerformance",
                      "yearlyToDatePerformance",
                      "yearlyPerformance",
                    ].map((perf, idx) => (
                      <td
                        key={idx}
                        className={`px-2 py-3 ${roundToOneDecimal(item[perf]) < 0
                          ? `bg-[${colorScheme.danger.color}]`
                          : roundToOneDecimal(item[perf]) === 0
                            ? `bg-none`
                            : roundToOneDecimal(item[perf]) == null
                              ? `bg-none`
                              : `bg-[${colorScheme.success.color}]`
                          }`}
                      >
                        {roundToOneDecimal(item[perf]) ?? 0}%
                      </td>
                    ))}

                    {/* Trade Date */}
                    <td style={{ minWidth: "8.1vw" }}>
                      {item.TradeDate && Array.isArray(item.TradeDate)
                        ? new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(new Date(item.TradeDate[0], item.TradeDate[1] - 1, item.TradeDate[2]))
                        : "Invalid Date"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={14} className="text-center px-6 py-4">
                    No data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </PerfectScrollbar>

      <Modal isOpen={isModalOpen} onClose={closeModal} data={selectedData}>
        <ChartData selectedData={selectedData} />
      </Modal>
    </section>
  );
};

export default Table;
